module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"environments":["production"],"googleAnalytics":{"trackingId":"G-RVF4370QT6","cookieName":"gdpr-analytics-enabled"},"googleTagManager":{"trackingId":"GTM-MDFXTML","cookieName":"gdpr-analytics-enabled"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"madewithlove","short_name":"madewithlove","start_url":"/","background_color":"#ed2324","theme_color":"#ed2324","display":"standalone","icon":"static/icon.jpg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"cb8903a2aa73c6039c7e6aeb3f10e517"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr"],"defaultLanguage":"en","siteUrl":"https://madewithlove.com","trailingSlash":"always","fallbackLanguage":"en","i18nextOptions":{"debug":false,"fallbackLng":"en","interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/team/:member?","languages":["en"]},{"matchPath":"/404","languages":["en"]},{"matchPath":"/about/:path?/:path2?/","languages":["en"]},{"matchPath":"/careers","languages":["en"]},{"matchPath":"/cto-coaching-and-mentoring","languages":["en"]},{"matchPath":"/cto-sounding-board","languages":["en"]},{"matchPath":"/cookie-policy","languages":["en"]},{"matchPath":"/for-scale-ups","languages":["en"]},{"matchPath":"/for-startups-and-scale-ups/","languages":["en"]},{"matchPath":"/helping-saas-teams","languages":["en"]},{"matchPath":"/interim-cto","languages":["en"]},{"matchPath":"/privacy-policy","languages":["en"]},{"matchPath":"/technical-due-diligence","languages":["en"]},{"matchPath":"/services/","languages":["en"]},{"matchPath":"/services/technical-leadership/engineering-management","languages":["en"]},{"matchPath":"/services/services-for-accelerators/","languages":["en"]},{"matchPath":"/services/product-management/","languages":["en"]},{"matchPath":"/services/recruiting/","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
